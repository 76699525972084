@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

a {
  color: black; text-decoration: none;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0; background-color: #f9f4ef;
}

