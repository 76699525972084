.meal-form__image {
    max-height: 37px;
}

.tox-tinymce {
    border-radius: 4px !important;
}

.meal-form__recipe-from-image__tooltip {
    position: relative;
}

.meal-form__recipe-from-image__tooltip__icon {
    font-size: 1.1rem;
    position: relative;
    top: 5px;
    margin-right: 2px;
}

.meal-form__name {
    width: 100%;
}
