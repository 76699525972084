.meal-item__icon {
    max-width: 85px;
}

.meal__date * {
    display: inline-block;
}

.meal__date {
    font-size: 0.8em;
    padding-bottom: 4px;
}

.meal__name {
    font-size: 1.1em;
    padding: 4px 0;
}

.meal-list .sorting {
    float: right;
}

.meal-list .sorting .sorting__select .MuiSelect-select {
    padding: 8px 32px 7px 12px;
}

.meal__date.component-date__day {
    margin-left: 3px;
    font-weight: bold;
}

.meal-detail__controls {
    float: right;
}