#logo {
    display: block;
    margin: 0 auto;
    vertical-align: center;
}

#logo img {
    max-width: 80px;
    vertical-align: center;
    margin-top: 7px;
}

.burger-menu {
    float: left;
    margin-top: 12px !important;
}

.profile-avatar {
    float: right;
    margin-top: 8px;
}

.header-toolbar > div {
    width: 100%;
}